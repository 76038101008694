




import { Component, Mixins } from 'vue-property-decorator'

import MGbyCourseMixin from '@/mixins/master/GetMGbyCourseMixin'

@Component
export default class GoToMasterLesson extends Mixins(MGbyCourseMixin) {
  protected get lessonID () {
    return +this.$route.params.lessonID
  }

  private mounted () {
    this.getMasterGroup()
      .then(() => {
        this.$router.replace({
          name: 'master.lessons.item',
          params: {
            groupID: this.currentMasterGroupID.toString(),
            lessonID: this.lessonID.toString(),
          },
        })
      })
  }
}
