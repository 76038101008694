import { Component, Mixins } from 'vue-property-decorator'

import NotifyMixin from '@/mixins/NotifyMixin'
import MasterEducationModule from '@/store/modules/master/education'

@Component
export default class MGbyCourseMixin extends Mixins(NotifyMixin) {
  protected get courseID () {
    return +this.$route.params.courseID
  }

  protected get currentMasterGroupID () {
    return MasterEducationModule.currentMasterGroupID
  }

  protected getMasterGroup () {
    return MasterEducationModule.fetchMasterGroupByCourse(this.courseID)
      .catch((error: any) => {
        this.$router.replace({ name: 'master' })
        this.notifyError(error)
      })
  }
}
